import React from 'react';
import icons from '../../assets/icons/icons';


const LoaderMain = () => {
  return (
    <div className='app-loader loading'>
      {icons.Loader('')}
    </div>
  )
}

export default LoaderMain