export const isIterableArray = (array: any[]) => Array.isArray(array) && array.length > 0;

export const affirprice = (price: any) => {
  if (Number.isInteger(price)) {
    return price + '00';
  } else {
    let fixedprice = price.toFixed(2);
    let pricestring = String(fixedprice);
    return pricestring.replace('.', '');
  }
};

export const formatCurrency = (num: any) => {
  var num_parts = num.toString().split('.');
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return num_parts.join('.');
};

export const pagination = (currentPage: number, maximumPages: number) => {
  var current = currentPage,
    last = maximumPages,
    delta = 1,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    l;

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i);
    l = i;
  }
  return rangeWithDots;
};

export const getDate = (date: string) => {
  const LocalFormat = new Date(date);
  const DD = LocalFormat.getDate();
  const MM = LocalFormat.toLocaleString('default', { month: 'long' });
  const YY = LocalFormat.getFullYear();
  return `${MM} ${DD}, ${YY}`;
};

export const getTime = (date: string) => {
  const LocalFormat = new Date(date);
  var hours = LocalFormat.getHours();
  var minutes: any = LocalFormat.getMinutes();
  const AMorPM = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const time = `${hours}:${minutes} ${AMorPM}`;
  return time;
};

export const getQuery = (location: any) => {
  return new URLSearchParams(location);
};

export const extractContent = (html: any, maxLength: number) => {
  const metaDis: any = new DOMParser().parseFromString(html, 'text/html').documentElement.textContent;

  var trimmedString = metaDis.substr(0, maxLength);
  //Trim and re-trim only when necessary (prevent re-trim when string is shorted than maxLength, it causes last word cut)
  if (metaDis.length > trimmedString.length) {
    //trim the string to the maximum length

    //re-trim if we are in the middle of a word and
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));
  }
  return trimmedString;
};


export const capitalFirstLetter =(str: string) => {
  return str.slice(0)[0].toUpperCase + str.slice(1);
}

export const formatPrice =(price: string) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
