declare const window: any;

export const eventAddToCart = (data: any) => {
  window.gtag('event', 'add_to_cart', data);
  window.fbq('track', 'AddToCart', data);
};

export const eventRemoveFromCart = (data: any) => {
  window.gtag('event', 'remove_from_cart', data);
};

export const eventQuantityChange = (data: any) => {
  window.gtag('event', 'quantity_change', data);
};

export const eventPurchasedComplete = (data: any) => {
  window.gtag('event', 'purchase', data);
  window.fbq('track', 'Purchase', data);
};