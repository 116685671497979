const Toast = {
  success: '#02cc37',
  failure: '#cc0202',
  warning: '#ff4b05'
};

const Regex = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};

const Stripe = process.env.NEXT_PUBLIC_STRIPE_PK

const componentConst = { Toast, Regex, Stripe };

export default componentConst;
