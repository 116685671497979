const getKey = (key: string) => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key);
  }

};
const setKey = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

const appService = {
  getKey,
  setKey
};

export default appService;
