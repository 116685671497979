import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import API from "../../config/config";
import httpService from "../../services/httpService";
import appService from "../../services/appService";
import icons from "../../assets/icons/icons";
import color from "../../assets/colors";
import { eventAddToCart } from "../../helpers/events";

export default function WhiteGloveAssembly({
  handleHideAssembly,
  showLoader,
  hideLoader,
  setCartItems,
  data,
}) {
  const [selectedVariant, setSelectedVariant]: any = useState({});
  const [price, setPrice] = useState(null);
  const [reqObj, setReqObj] = useState({});
  const [assemblyLoading, setAssemblyLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const { asPath } = useRouter();

  useEffect(() => {
    const pathValues = {
      "n3-elite": "N3 Elite",
      "n3-commercial": "N3 Commercial",
      "n3-pro": "N3 Pro",
      "n3-youth": "N3 Youth",
      "n3-elite-bundles": "N3 Elite",
    };

    let routeValue = "";
    if (asPath?.split("/")[2]) {
      routeValue = pathValues[asPath?.split("/")[2]];
    } else {
      routeValue = pathValues[asPath?.split("/")[1]];
    }

    if (data && data?.length > 0 && data[0] && data[0]?.ProductVariant) {
      data[0].ProductVariant?.map((item) => {
        if (
          item?.__component === "product.variant" &&
          item?.Variants &&
          item.Variants?.length > 0
        ) {
          const varLength = item.Variants?.length;
          for (let i = 0; i < varLength; i++) {
            if (item.Variants[i]?.value === routeValue) {
              setSelectedVariant(item.Variants[i]);
              setPrice(
                item.Variants[i].newPrice
                  ? item.Variants[i].newPrice
                  : item.Variants[i].price
              );
              const reqObj = {
                quantity: 1,
                productId: data[0].id,
                variantLabel: "Model",
                selectedOption: routeValue,
              };
              setReqObj(reqObj);
            }
          }
        }
      });
    }
  }, [data]);

  const GoogleAnalyticsAddToCart = () => {
    let prod = {};
    let price;
    if (
      selectedVariant &&
      (selectedVariant.newPrice !== null || selectedVariant.price !== null)
    ) {
      price =
        selectedVariant.newPrice !== null
          ? selectedVariant.newPrice
          : selectedVariant.price;
    } else {
      price = data[0].newPrice !== null ? data[0].newPrice : data[0].price;
    }
    if (data[0].product_categories && data[0].product_categories.length > 0) {
      let category = "";
      data[0].product_categories.forEach((element: any, i: number) => {
        if (element.name) {
          category += element.name;
          if (i < data[0].product_categories.length) {
            category += "/";
          }
        }
      });
      prod = { ...prod, category: category };
    }

    prod = {
      ...prod,
      id: data[0].id,
      name: data[0].displayTitle ? data[0].displayTitle : data[0].title,
      discount:
        data[0].newPrice !== null ? data[0].price - data[0].newPrice : 0,
      affiliation: "Nexersys Store",
      brand: "Nexersys",
      // item_category: 'pants',
      price: price,
      currency: data[0].currency ? data[0].currency.code : "USD",
      quantity: 1,
    };

    if (selectedVariant) {
      prod = { ...prod, variant: selectedVariant.value };
    }

    const item = {
      currency: "USD",
      items: [prod],
      value: price,
    };
    eventAddToCart(item);
  };

  const handleSubmit = () => {
    const cartid = appService.getKey("cartid");
    showLoader();
    setAssemblyLoading(true);
    httpService.invokeApi(
      {
        url: `${API.endpoints.getCart}/${cartid}/cartitems`,
        method: "put",
        data: reqObj,
      },
      (success: boolean, res: any) => {
        if (success && res.id) {
          GoogleAnalyticsAddToCart();
          const request: any = { method: "get", url: API.endpoints.getCart };
          if (cartid) {
            request.headers = { cartid: cartid };
          }
          httpService.invokeApi(request, (success: boolean, res: any) => {
            if (success) {
              appService.setKey("cartid", res.cartId);
              setCartItems(res);
              setAssemblyLoading(false);
              hideLoader();
            } else {
              setAssemblyLoading(false);
              hideLoader();
            }
          });
        } else {
          setAssemblyLoading(false);
          if (
            res?.message === "ValidationError" &&
            selectedVariant?.errorMessage
          ) {
            setErrors(selectedVariant?.errorMessage || "Something went wrong");
          }
          hideLoader();
        }
      }
    );
  };

  return (
    <div className="white-gove-assembly-container">
      <div className="app-overlay" onClick={handleHideAssembly} />
      <div className="white-gove-assembly-wrapper">
        <div className="info-container">
          <span
            className="bundle-close-icon"
            onClick={handleHideAssembly}
            style={{ top: 20 }}
          >
            {icons.close(color.$black, "")}
          </span>
          {assemblyLoading && <div className="assembly-loader">Loading...</div>}
          {errors && <div className="error-div">{errors}</div>}
          <div className="assembly-info-wrapper">
            <div className="assembly-element-col">
              <div className="asselbly-title-container">
                {data &&
                  data[0] &&
                  data[0]?.AdditionalInfo &&
                  data[0]?.AdditionalInfo[0]?.label && (
                    <>
                      <h6>{data[0]?.AdditionalInfo[0]?.label}</h6>
                      <div
                        className="white-glove-info"
                        dangerouslySetInnerHTML={{
                          __html: data[0]?.AdditionalInfo[0]?.description,
                        }}
                      />
                    </>
                  )}
              </div>
              <div className="assembly-price-container">
                <div className="assembly-price-Wrapper">
                  <span className="price-border">{`$${price?.toFixed(
                    2
                  )}`}</span>
                </div>
              </div>
              <div
                className="product-elite-button-wrapper"
                style={{ marginBottom: 0 }}
              >
                <button
                  style={{ backgroundColor: "#d3222a" }}
                  onClick={handleSubmit}
                >
                  ADD TO CART
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
