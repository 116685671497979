const init = {
  cart: null,
  cartStatus: false,
  ratingStatus: false,
  rating: null,
  loader: false
};

const reducer = (state: any = init, action: any) => {
  switch (action.type) {
    case 'TOGGLE_CART':
      if (state.cartStatus === false) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
      return { ...state, cartStatus: !state.cartStatus };
    case 'ADD_TO_CART':
      return;
    case 'REMOVE_FROM_CART':
      return;
    case 'ON_CART_LOAD':
      return { ...state, cart: action.payload };
    case 'RATING_TOGGLE':
      return { ...state, ratingStatus: !state.ratingStatus };
    case 'ON_RATING_LOAD':
      return { ...state, rating: action.payload };
    case 'LOADER_SHOW':
      return { ...state, loader: true, isLoadingPage: action.isLoadingPage ? true : false };
    case 'LOADER_HIDE':
      return { ...state, loader: false };
  }
  return state;
};

export default reducer
