import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

const QuantityComponent = (props: any) => {
  const [quantity, setQuantity]: any = useState(1);
  useEffect(() => {
    if (props.quantity) {
      setQuantity(props.quantity);
    }
  }, [props.quantity]);
  return (
    <div className="quantity-input">
      <Button
        variant="btn btn-light"
        className="quantity-button form-control"
        onClick={() => {
          if (quantity > 1 || props.enableZero === true) {
            setQuantity(quantity - 1);
            if (props.onChange && props.id) {
              props.onChange(quantity - 1, props.id);
            } else {
              props.onChange(quantity - 1);
            }
          }
        }}
      >
        -
      </Button>
      <input
        type="number"
        aria-label="quantity"
        value={quantity}
        name="quantity"
        className="form-control"
        onChange={$event => {
          if (props.enableZero === false) {
            if (+$event.target.value === 0) {
              return;
            }
          } else {
            setQuantity(+$event.target.value);
            if (props.onChange && props.id) {
              props.onChange(+$event.target.value, props.id);
            } else {
              props.onChange(+$event.target.value);
            }
          }
        }}
      />
      <Button
        variant="btn btn-light"
        className="quantity-button form-control"
        onClick={() => {
          setQuantity(quantity + 1);
          if (props.onChange && props.id) {
            props.onChange(quantity + 1, props.id);
          } else {
            props.onChange(quantity + 1);
          }
        }}
      >
        +
      </Button>
    </div>
  );
};

export default QuantityComponent;
