import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StarFill } from 'react-bootstrap-icons';

const ReviewStars = ({ mode, ratedStars, onChange }: any) => {
  let starset = [];
  const [stars, setStars] = useState(mode === 1 ? 0 : ratedStars);
  const [starStatus, setstarStatus] = useState(mode === 1 ? false : true);
  for (let index = 0; index < 5; index++) {
    starset.push(
      <StarFill
        onClick={() => {
          if (mode === 1) {
            setstarStatus(true);
            setStars(index + 1);
            onChange(index + 1);
          }
        }}
        onMouseEnter={() => {
          if (mode === 1 && !starStatus) {
            setStars(index + 1);
          }
        }}
        onMouseLeave={() => {
          if (mode === 1 && !starStatus) {
            setStars(0);
          }
        }}
        className={'app-stars ' + (index < stars ? 'active' : 'inactive')}
        key={index + 1}
      />
    );
  }
  return (
    <div className="app-review-stars-container">
      {starset}
      {mode === 1 && (
        <input
          type="text"
          name="rating"
          value={stars > 0 ? stars : ''}
          onChange={() => {
          }}
          style={{ height: '0px', width: '0px', opacity: 0 }}
          required
        />
      )}
    </div>
  );
};

ReviewStars.propTypes = {
  mode: PropTypes.number.isRequired,
  ratedStars: PropTypes.number,
  onChange: PropTypes.func
};

export default ReviewStars;
