import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-multi-carousel/lib/styles.css'
import 'react-photoswipe/lib/photoswipe.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import '../styles/style.scss'
import '../styles/Debootstraping.scss'
import '../styles/ProductContainer.scss'
import '../styles/HomePageContainer.scss'
import '../styles/ProductListingContainer.scss'
import '../styles/index.scss'

import '../components/Accessibility/Accessibility.scss'
import '../components/Accordion/SingleAccordion.scss'
import '../components/AnimatedLevelCard/AnimatedLevelCard.scss'
import '../components/AnimatedLevelCard/CircularProgressbar.scss'
import '../components/Blog/Article/Article.scss'
import '../components/Blog/ArticleCard/ArticleCard.scss'
import '../components/Blog/ArticleCardListing/ArticleCardListing.scss'
import '../components/Blog/ArticleContainer/ArticleContainer.scss'
import '../components/Blog/ArticleTypeCard/ArticleTypeCard.scss'
import '../components/Blog/ArticleTypeListing/ArticleTypeListing.scss'
import '../components/Blog/Comment/Comment.scss'
import '../components/Blog/CommentSection/CommentSection.scss'
import '../components/Blog/RecentArticle/RecentArticle.scss'
import '../components/Blog/TypeList/TypeList.scss'
import '../components/BundlePopup/BundlePopup.scss'
import '../components/CardCarouselInstagram/CardCarouselInstagram.scss'
import '../components/CardCarouselRatingWidget/CardCarouselRating.scss'
import '../components/CardCarouselRatingWidget/CardCarouselRatingGroup.scss'
import '../components/CardImageGroup/TextImageCardGroup.scss'
import '../components/CardImageGroup/TextImageCardItem.scss'
import '../components/CardProductSale/GroupCardProductSale.scss'
import '../components/CarouselImageWidget/CarouselImageWidget.scss'
import '../components/CarouselWidget/CarouselWidget.scss'
import '../components/CartSlider/CartSlider.scss'
import '../components/CheckoutForm/CheckoutForm.scss'
import '../components/CheckoutForm/CheckoutTable.scss'
import '../components/ColorBar/ColorBar.scss'
import '../components/confirmationMessage/confirmationMessage.scss'
import '../components/ConfirmCheckout/ConfirmCheckout.scss'
import '../components/ContactFormWidget/ContactFormAccordion.scss'
import '../components/ContactFormWidget/ContactFormAccordionGroup.scss'
import '../components/ContactFormWidget/ContactFormWidget.scss'
import '../components/contentPopup/contentPopup.scss'
import '../components/CustomerSurvey/CustomerSurveyHeader.scss'
import '../components/CustomerSurvey/CustomerSurveyForm.scss'
import '../components/CustomerSurvey/customerSurvey-2.scss'
import '../components/CustomerSurvey/CustomerSurvey-3.scss'
import '../components/DiscountBanner/DiscountBanner.scss'
import '../components/DownloadButton/DownloadButton.scss'
import '../components/DownloadSection/DownloadSection.scss'
import '../components/Faq/Faq.scss'
import '../components/Faq/FaqsHeaderwidget.scss'
import '../components/Footer/Footer.scss'
import '../components/GroupTechCardWidget/GroupTechCardWidget.scss'
import '../components/Header/Header.scss'
import '../components/HeroDiscountForm/HeroesFormAccordion.scss'
import '../components/ImageTableCausel/ImageTableCausel.scss'
import '../components/Loader/Loader.scss'
import '../components/LeaveReviewForm/LeaveReviewForm.scss'
import '../components/Manual/Manual.scss'
import '../components/Manual/ManualHeader.scss'
import '../components/MilitaryFirst/MilitaryFirst.scss'
import '../components/MobileViewRatingCarosel/MobileViewRatingCarosel.scss'
import '../components/ModelViewerWidget/ModelViewerWidget.scss'
import '../components/Navigation/NavigationGroup.scss'
import '../components/NexersysLocationWidget/NexersysLocationWidget.scss'
import '../components/NoSubscriptiondetails/NoSubscriptiondetails.scss'
import '../components/NoWarrantyApplication/NoWarrantyApplication.scss'
import '../components/N3LeadForm/N3LeadFormWidget.scss'
import '../components/N3LeadForm/N3FormHeader.scss'
import '../components/OfferWidget/OfferWidget.scss'
import '../components/OpacityImage/OpacityImage.scss'
import '../components/Pagination/Pagination.scss'
import '../components/payment Gateway/affirm payment/Affirmpayment.scss'
import '../components/payment Gateway/stripe/Stripe.scss'
import '../components/PrivacyPolicy/Privacy.scss'
import '../components/ProductBadgeWidget/ProductBadgeWidget.scss'
import '../components/ProductsReviewWidget/ProductsReviewWidget.scss'
import '../components/PromotionalBanner/PromotionalBanner.scss'
import '../components/MilitaryDiscount/MilitaryDiscount.scss'
import '../components/ProductTestimonialsWidget/ProductTestimonialsWidget.scss'
import '../components/ProductCardWidget/ProductCardWidget.scss'
import '../components/ProductCardGroup/ProductCardGroup.scss'
import '../components/ProductDetails/ProductDetails.scss'
import '../components/ProductBenefits/ProductBenefits.scss'
import '../components/MiniWidgetFeedbackCard/MiniWidgetFeedbackCard.scss'
import '../components/TrainerImageCard/TrainerImageLanding.scss'
import "../components/CardSlliderAsNav/CardSlliderAsNav.scss"
import "../components/TestimonialRatings/TestimonialRatings.scss"
import '../components/ProductDetailWidget/ProductDetailWidget.scss'
import '../components/ProductFeatureBadgeWidget/ProductFeatureBadgeWidget.scss'
import '../components/ProductFeatureContent/ProductFeatureContent.scss'
import '../components/productFeatureWidget/productFeatureWidget.scss'
import '../components/ProductListing/ProductListing.scss'
import '../components/whiteGloveAssembly/whiteGloveAssembly.scss'
import '../components/ProductMediaWidget/ProductMediaWidget.scss'
import '../components/ProductOverView/ProductOverView.scss'
import '../components/ProductSpecificationWidget/ProductSpecificationWidget.scss'
import '../components/ProductUsageCardWidget/ProductUsageCardWidget.scss'
import '../components/ProductVideoContent/ProductVideoContent.scss'
import '../components/QuantityComponent/QuantityComponent.scss'
import '../components/RadioButton/RadioButton.scss'
import '../components/RatingPopup/RatingPopup.scss'
import '../components/ReturnPolicy/ReturnPolicy.scss'
import '../components/ReturnRequestForm/ReturnRequestFormAccordion.scss'
import '../components/ReviewStars/ReviewStars.scss'
import '../components/Search/SearchCard/SearchCard.scss'
import '../components/Search/Search.scss'
import '../components/Search/SearchPage.scss'
import '../components/SocialMediaBanner/SocialMediaBanner.scss'
import '../components/StartBoxingShop/StartBoxingShop.scss'
import '../components/table/table.scss'
import '../components/TermsOfUse/TermsOfUse.scss'
import '../components/Testimonials/Testimonials.scss'
import '../components/Toast/Toast.scss'
import '../components/TrainerImageCard/TrainerImageCard.scss'
import '../components/TrainingSection/TrainingSection.scss'
import '../components/TrainingVideo/TrainingVideo.scss'
import '../components/TrainingVideo/TrainingVideoHeader.scss'
import '../components/TransparentRating/TransparentRating.scss'
import '../components/TShirtForm/TshirtFormWidget.scss'
import '../components/TShirtForm/TshirtFormWidgetHeader.scss'
import '../components/TShirtForm/TShirtFormSuccessMessage.scss'
import '../components/UpdateSoftware/UpdateSoftware.scss'
import '../components/VideoImageSection/VideoImageSectionGroup.scss'
import '../components/VideoImageSection/VideoImageWidgetItem.scss'
import '../components/VideoPlayer/Player.scss'
import '../components/WidgetPlayer/WidgetPlayer.scss'
import '../components/TrainLikeAFighter/TrainLikeAFighter.scss'
import '../components/AffirmPrequalify/AffirmPrequalify.scss'
import '../components/ExtendWarrantyCart/extendWarrantyCart.scss'

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import reducer from '../store/reducer'
import ReactGA from 'react-ga'
import { hotjar } from 'react-hotjar'
import React, { useState, useEffect } from 'react'
import Loader from '../components/Loader/Loader'
import LoaderMain from '../components/Loader/LoaderMain'
import CartSlider from '../components/CartSlider/CartSlider'
import RatingPopup from '../components/RatingPopup/RatingPopup'
import Router from 'next/router'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'


function MyApp({ Component, pageProps }) {
  const [loading, setLoading] = useState(false);
  const store = createStore(reducer);
  ReactGA.initialize(process.env.NEXT_PUBLIC_TRACKING_ID);

  useEffect(() => {
      // only initialize when in the browser
      if (typeof window !== 'undefined') {
        LogRocket.init(`${process.env.NEXT_PUBLIC_LOGROCKET}`);
      // plugins should also only be initialized when in the browser
        setupLogRocketReact(LogRocket);
      }
  }, [])

  useEffect(() => window.fbq('track', 'PageView'), []);

  useEffect(() => hotjar.initialize(process.env.NEXT_PUBLIC_HJID, process.env.NEXT_PUBLIC_HJSV), []);

  Router.events.on('routeChangeStart', () => {
    setLoading(true)
  });

  Router.events.on('routeChangeComplete', () => {
    setLoading(false)
    window.fbq('track', 'PageView')
  });

  return (
    <Provider store={store} >
      <React.Fragment>
        <div className='app-layout'>
          
            <Component {...pageProps} />
          
        </div>
        {loading && <LoaderMain />}
        <CartSlider />
        <RatingPopup />
        <Loader />
      </React.Fragment>
    </Provider>
  )
}

export default MyApp;