import React from 'react';
import icons from '../../assets/icons/icons';
import { connect } from 'react-redux';

const Loader = ({ status, isLoadingPage }: any) => {
  return (
    status && (
      <div className={'app-loader ' + (isLoadingPage ? 'loading' : '')}>
        {isLoadingPage ? icons.Loader('') : icons.pulseLoader('#fff', 'fa-2x')}
      </div>
    )
  );
};

const mapState = (state: any) => {
  return {
    status: state.loader,
    isLoadingPage: state.isLoadingPage
  };
};

const mapDispatch = (dispatch: any) => {
  return {};
};

export default connect(
  mapState,
  mapDispatch
)(Loader);
