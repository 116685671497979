import React, { useState } from 'react';
import { connect } from 'react-redux';
import icon from '../../assets/icons/icons';
import color from '../../assets/colors';
import { Form } from 'react-bootstrap';
import ReviewStars from '../ReviewStars/ReviewStars';
import httpService from '../../services/httpService';
import API from '../../config/config';
import { MyToast } from '../Toast/Toast';
import componentConst from '../../config/componentConfig';

const RatingPopup = ({ data, options, ratingStatus, rating, onToggle, setRatingData }: any) => {
  const [reviewStatus, setReviewStatus] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [formData, setFormData]: any = useState({
    email: '',
    review: '',
    name: '',
    rating: 0
  });

  const re = new RegExp(componentConst.Regex.email);

  const [errors, setErrors] = useState({
    emailError: ''
  });

  const [toast, setToast]: any = useState({ message: '', visible: false, timeOut: 5000, type: 'success' });

  const isEven = (value: number) => {
    if (value % 2 === 0) {
      return true;
    } else {
      return false;
    }
  };

  const close = () => {
    onToggle();
    resetForm();
    setSeeMore(false);
    setReviewStatus(false);
  };

  const resetToast = () => {
    setTimeout(() => {
      setToast({ ...toast, visible: false });
    }, 5000);
  };

  const resetForm = () => {
    setFormData({
      email: '',
      review: '',
      name: '',
      rating: 0
    });
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (formData.email && formData.name && formData.review && formData.rating) {
      const data = {
        email: formData.email.trim(),
        name: formData.name.trim(),
        review: formData.review,
        rating: formData.rating
      };
      if (!re.test(String(data.email).toLowerCase())) {
        setErrors({ ...errors, emailError: 'Invalid Email' });
        return;
      } else {
        setErrors({ ...errors, emailError: '' });
      }
      httpService.invokeApi(
        { url: `${API.endpoints.products}/${rating.id}/${API.endpoints.review}`, data, method: 'post' },
        (success: boolean, response: any) => {
          if (success && response.id) {
            onToggle();
            setToast({ ...toast, message: 'Review has been submitted successfully', visible: true, type: 'success' });
            resetForm();
            resetToast();
            setReviewStatus(false);
            setRatingData(null);
          } else {
            if (response) {
              if (response.data && response.data.errors instanceof Object) {
                let errors: any = Object.values(response.data.errors);
                if (errors.length > 0) {
                  setToast({ message: errors[0], visible: true, type: 'failure' });
                  resetToast();
                } else {
                  setToast({ message: 'an error occurred', visible: true, type: 'failure' });
                  resetToast();
                }
              } else {
                setToast({ message: 'an error occurred', visible: true, type: 'failure' });
                resetToast();
              }
            }
          }
        }
      );
    }
  };

  const onChangeHandler = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const onBlur = (event: any) => {
    const value = event.target.value.trim();
    if (value && !re.test(String(value).toLowerCase())) {
      setErrors({ ...errors, emailError: 'Invalid Email' });
      return;
    } else {
      setErrors({ ...errors, emailError: '' });
    }
  };

  return (
    <React.Fragment>
      <MyToast message={toast.message} visible={toast.visible} type={toast.type} timeOut={toast.timeOut} />
      {ratingStatus && (
        <div className="app-rating-popup">
          <div className="app-rating-container">
            <div className="app-rating-content">
              <span className="app-close-icon" onClick={close}>
                {icon.close(color.$black, '')}
              </span>
              <div className="app-content">
                {!reviewStatus &&
                  (rating && rating.reviews && rating.reviews.length > 0 && seeMore
                    ? rating.reviews.map((item: any, i: number) => {
                      return (
                        <div
                          key={i}
                          className="app-rating-data"
                          style={{ backgroundColor: isEven(i) ? '#F3F3F3' : '#fff' }}
                        >
                          <div className="app-stars-container">
                            <ReviewStars mode={0} ratedStars={item.rating} />
                          </div>
                          <p className="app-rating-name">{item.name}</p>
                          <p className="app-rating-des" dangerouslySetInnerHTML={{ __html: item.review }} />
                        </div>
                      );
                    })
                    : rating.reviews.slice(0, 3).map((item: any, i: number) => {
                      return (
                        <div
                          key={i}
                          className="app-rating-data"
                          style={{ backgroundColor: isEven(i) ? '#F3F3F3' : '#fff' }}
                        >
                          <div className="app-stars-container">
                            <ReviewStars mode={0} ratedStars={item.rating} />
                          </div>
                          <p className="app-rating-name">{item.name}</p>
                          <p className="app-rating-des" dangerouslySetInnerHTML={{ __html: item.review }} />
                        </div>
                      );
                    }))}
                {!reviewStatus &&
                  (rating.reviews && rating.reviews.length <= 0 && <div className="app-no-rating">No Reviews</div>)}
                {reviewStatus && (
                  <div className="app-review-form">
                    <span className="app-form-title">Add a review for “{rating.title}”</span>
                    <Form onSubmit={onSubmit}>
                      <Form.Label>Your rating *</Form.Label>
                      <ReviewStars
                        mode={1}
                        onChange={(value: number) => {
                          setFormData({ ...formData, rating: value });
                        }}
                      />
                      <Form.Label>Your review *</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="review"
                        rows={3}
                        required
                        onChange={onChangeHandler}
                        value={formData.review}
                      />
                      <Form.Label>Name *</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        autoComplete="none"
                        required
                        onChange={onChangeHandler}
                        value={formData.name}
                      />
                      <div className="form-control-con">
                        <Form.Label>Email *</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          autoComplete="none"
                          required
                          onChange={onChangeHandler}
                          value={formData.email}
                          className={errors.emailError ? ' error-field' : ''}
                          onBlur={onBlur}
                        />
                        {errors.emailError && <div className="error-message">{errors.emailError}</div>}
                      </div>
                      <Form.Control type="submit" value="submit" />
                    </Form>
                  </div>
                )}
                {!seeMore && !reviewStatus && (
                  <div className="app-rating-footer">
                    {rating && rating.reviews && rating.reviews.length >= 3 && (
                      <span
                        className="app-see-more"
                        onClick={() => {
                          setSeeMore(true);
                        }}
                      >
                        See More...
                      </span>
                    )}
                    {/* <span
                      onClick={() => {
                        setReviewStatus(true);
                      }}
                      className="app-add-review"
                    >
                      Leave a Review
                    </span> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const mapState = (state: any) => {
  return {
    rating: state.rating,
    ratingStatus: state.ratingStatus
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    onToggle: () => dispatch({ type: 'RATING_TOGGLE' }),
    setRatingData: (RatingData: any) => {
      dispatch({ type: 'ON_RATING_LOAD', payload: RatingData });
    }
  };
};

export default connect(
  mapState,
  mapDispatch
)(RatingPopup);
