import React from 'react';
import componentConst from '../../config/componentConfig';
import { Toast } from 'react-bootstrap';

export const ToastSuccess = ({ message, visible }: any) => {
  return (
    <div className="app-toast-overlay">
      <Toast show={visible} className="app-toast" style={{ backgroundColor: componentConst.Toast.success }}>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </div>
  );
};

export const MyToast = ({ message, visible, type }: any) => {
  switch (type) {
    case 'failure':
      return <ToastFailure message={message} visible={visible} />;
    case 'warning':
      return <ToastWarning message={message} visible={visible} />;
    default:
      return <ToastSuccess message={message} visible={visible} />;
  }
};

export const ToastFailure = ({ message, visible }: any) => {
  return (
    <div className="app-toast-overlay">
      <Toast show={visible} className="app-toast" style={{ backgroundColor: componentConst.Toast.failure }}>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </div>
  );
};
export const ToastWarning = ({ message, visible }: any) => {
  return (
    <div className="app-toast-overlay">
      <Toast show={visible} className="app-toast" style={{ backgroundColor: componentConst.Toast.warning }}>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </div>
  );
};
