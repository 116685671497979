const routes = {
  homepage: '/',
  product: '/product/:key',
  pageNotFound: '/page-not-found',
  notfound: '*',
  bundle: '/n3-elite-bundles',
  productListing: '/nexersys-shop/:key',
  productDetails: '/product-details/:key',
  landingpage: '/n3e-landing',
  sportsTowel: '/seat-cover-sports-towel',
  connect: '/connect',
  connectgeneral: '/connect-general-info',
  checkout: '/checkout',
  testimonials: '/testimonials',
  faq: '/faq',
  trainingVideo: '/training-videos',
  manual: '/connect/manuals',
  blog: '/blog/:pageno',
  blogHome: '/blog',
  blogOne: '/blogs/:slug',
  blogs: '/blogs/:key/:value',
  blogsPage: '/blogs/:key/:value/page/:pageno',
  checkoutconfirm: '/checkout/order-received/:orderid',
  search: '/page/:pageno',
  searchQuery: '/?s=',
  connectConfirmation: '/connect-confirmation',
  blogAuthor: '/blogs/author',
  blogCategory: '/blogs/category',
  blogsUrl: '/blogs',
  blogTags: '/blogs/tag',
  productUrl: '/product',
  privacyPolicy: '/privacy-policy',
  termsofUser: '/terms-of-use',
  militaryFirst: '/military-first-responder-discount',
  update: '/update'
};

export default routes;
