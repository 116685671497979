import React, { useEffect, useState } from 'react';
import icon from '../../assets/icons/icons';
import color from '../../assets/colors';
import { connect } from 'react-redux';
import icons from '../../assets/icons/icons';
import QuantityComponent from '../../components/QuantityComponent/QuantityComponent';
import API from '../../services/httpService';
import appService from '../../services/appService';
import config from '../../config/config';
import ROUTE from '../../config/routes';
import { useRouter } from "next/router";
import { formatCurrency } from '../../helpers/utils';
import Link from 'next/link';
import { MyToast } from '../Toast/Toast';
import { eventQuantityChange, eventRemoveFromCart } from '../../helpers/events';
import WhiteGloveAssembly from "../../components/whiteGloveAssembly/whiteGloveAssembly"
import httpService from '../../services/httpService';
import ExtendWarrantyCart from '../ExtendWarrantyCart/extendWarrantyCart';

const CartSlider = ({ status, onToggle, setCartItems, cart, showLoader, hideLoader }: any) => {
  const [Loading, setLoading] = useState(false);
  const cartId = appService.getKey('cartid');
  const priceSections = ['subTotal', 'shipping', 'discount', 'tax', 'total'];
  const [coupon, setCoupon] = useState('');
  const [toast, setToast]: any = useState({ message: '', visible: false, timeOut: 5000, type: 'success' });
  const [isCouponHidden, setIsCouponHidden] = useState(false);
  const [showAssembly, setShowAssembly] = useState(false);
  const [assemblyData,setAssemblyData] = useState([]);
  const [isLoading,setIsLoading] = useState(false);

  const { asPath } = useRouter();
  const pathValues = {
    "n3-elite": true,
    "n3-commercial": true,
    "n3-pro": true,
    "n3-youth": true,
    "n3-elite-bundles": true
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";
    showLoader();
    setIsLoading(true)
    API.invokeApi(
      { method: "get", url: `${config.endpoints.products}?title_eq=On Site Assembly` },
      (success: boolean, res: any) => {
        if (success) {
          const finalRes = res && res?.length > 0 &&
          res.filter(data => data.title === "On Site Assembly")
          
          setAssemblyData(finalRes)
          setIsLoading(false)
          hideLoader();
        } else {
          hideLoader();
          setIsLoading(false)
        }
      }
    );
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    const request: any = { method: 'get', url: config.endpoints.getCart };
    const getData = () => {
      if (appService.getKey('cartid')) {
        request.headers = { cartid: appService.getKey('cartid') };
      }
      API.invokeApi(request, (success: boolean, res: any) => {
        if (success) {
          appService.setKey('cartid', res.cartId);
          setCartItems(res);
        }
      });
    };
    getData();
  }, [setCartItems]);

  useEffect(() => {
    getDiscount();
    if (status) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  });

  const resetToast = () => {
    setTimeout(() => {
      setToast({ ...toast, visible: false });
    }, 5000);
  };

  const applyCoupon = () => {
    if (coupon === '') {
      return;
    }
    setLoading(true);
    const request = { url: `${config.endpoints.getCart}/${cartId}/coupons`, data: { code: coupon }, method: 'post' };
    API.invokeApi(request, (success: boolean, response: any) => {
      if (success) {
        setCoupon('');
        getCart();
        setToast({ ...toast, message: response.message, visible: true, type: 'success' });
        resetToast();
        setIsCouponHidden(false);
      } else {
        let err;
        if (response.data) {
          if (response.data.errors && response.data.errors.length > 0) {
            err = response.data.errors[0].message;
          } else {
            err = 'An Error occured';
          }
        } else {
          err = 'An Error occured';
        }
        setCoupon('');
        setToast({ ...toast, message: err, visible: true, type: 'failure' });
        resetToast();
        setLoading(false);
        setIsCouponHidden(false);
      }
    });
  };

  const keyPress = (event: any) => {
    if (event.charCode === 13) {
      applyCoupon();
    }
  };

  const getCart = () => {
    const request: any = { method: 'get', url: config.endpoints.getCart };
    if (cartId) {
      request.headers = { cartid: cartId };
    }
    API.invokeApi(request, (success: boolean, res: any) => {
      if (success) {
        appService.setKey('cartid', res.cartId);
        setCartItems(res);
      }
      setLoading(false);
    });
  };

  const googleAnalyticsRemoveFromCart = (item: any) => {
    let price = 0;
    let title = '';
    let items = {};
    let id;
    let discount = 0;
    if (item.selectedOption) {
      items = { ...items, variant: item.selectedOption };
    }
    if (item.product) {
      title = item.product.displayTitle ? item.product.displayTitle : item.product.title;
      id = item.product.id;
      if (item.selectedOption && item.product.ProductVariant && item.product.ProductVariant.length > 0) {
        const ProductVariant = item.product.ProductVariant.find((element: any) => item.variantLabel === element.label);
        if (ProductVariant) {
          const variant = ProductVariant.Variants.find((ele: any) => ele.value === item.selectedOption);
          if (variant && (variant.newPrice || variant.price)) {
            price = variant.newPrice !== null ? variant.newPrice : variant.price;
            discount = variant.newPrice !== null ? variant.price - variant.newPrice : 0;
          } else {
            price = item.product.newPrice !== null ? item.product.newPrice : item.product.price;
            discount = item.product.newPrice !== null ? item.product.price - item.product.newPrice : 0;
          }
        } else {
          price = item.product.newPrice !== null ? item.product.newPrice : item.product.price;
          discount = item.product.newPrice !== null ? item.product.price - item.product.newPrice : 0;
        }
      } else {
        price = item.product.newPrice !== null ? item.product.newPrice : item.product.price;
        discount = item.product.newPrice !== null ? item.product.price - item.product.newPrice : 0;
      }
      if (item.product.product_categories && item.product.product_categories.length > 0) {
        let category = '';
        item.product.product_categories.forEach((element: any, i: number) => {
          if (element.name) {
            category += element.name;
            if (i < item.product_categories.length) {
              category += '/';
            }
          }
        });
        items = { ...items, category: category };
      }
    }
    if (item.product_bundle) {
      title = item.product_bundle.displayTitle ? item.product_bundle.displayTitle : item.product_bundle.title;
      id = item.product_bundle.id;
      if (item.selectedOption && item.product_bundle.ProductVariant && item.product_bundle.ProductVariant.length > 0) {
        const ProductVariant = item.product_bundle.ProductVariant.find(
          (element: any) => item.variantLabel === element.label
        );
        if (ProductVariant) {
          const variant = ProductVariant.Variants.find((ele: any) => ele.value === item.selectedOption);
          if (variant && (variant.newPrice || variant.price)) {
            price = variant.newPrice !== null ? variant.newPrice : variant.price;
            discount = variant.newPrice !== null ? variant.newPrice - variant.price : 0;
          } else {
            price = item.product_bundle.newPrice !== null ? item.product_bundle.newPrice : item.product_bundle.price;
            discount =
              item.product_bundle.newPrice !== null ? item.product_bundle.price - item.product_bundle.newPrice : 0;
          }
        } else {
          price = item.product_bundle.newPrice !== null ? item.product_bundle.newPrice : item.product_bundle.price;
          discount =
            item.product_bundle.newPrice !== null ? item.product_bundle.price - item.product_bundle.newPrice : 0;
        }
      } else {
        price = item.product_bundle.newPrice !== null ? item.product_bundle.newPrice : item.product_bundle.price;
        discount = item.product_bundle.newPrice !== null ? item.product_bundle.price - item.product_bundle.newPrice : 0;
      }
      if (item.product_bundle.product_categories && item.product_bundle.product_categories.length > 0) {
        let category = '';
        item.product_bundle.product_categories.forEach((element: any, i: number) => {
          if (element.name) {
            category += element.name;
            if (i < item.product_categories.length) {
              category += '/';
            }
          }
        });
        items = { ...items, category: category };
      }
    }

    items = {
      ...items,
      id: id,
      name: title,
      discount: discount !== null ? +Math.abs(discount).toFixed(2) : 0,
      affiliation: 'Nexersys store',
      brand: 'Nexersys',
      price: price !== null ? price : 0,
      currency: 'USD',
      quantity: item.quantity
    };
    const data = {
      currency: 'USD',
      items: [items],
      value: item.subTotal
    };
    eventRemoveFromCart(data);
  };

  const UpdateCartItem = (quantity: number, id: number, productId?: number) => {
    setLoading(true);
    if(quantity===0){
      let warrantyItems;
      if(cart && cart.cart_items.length > 0)
      {
        // To filter out the associated extend warranties of a product or product bundle
        warrantyItems = cart.cart_items.filter(item => (item.extendContract && (item.extendContract.associatedProductId === `${productId}` || item.extendContract.associatedBundleId === `${productId}`)))
      }
      if(warrantyItems.length>0)
      {
        warrantyItems.forEach(item => {
          DeleteCartItem(item.id);
        });
      }
    }
    API.invokeApi(
      { method: 'patch', url: `${config.endpoints.getCart}/${cart.cartId}/cartitems/${id}`, data: { quantity } },
      (success: boolean, res: any) => {
        if (success) {
          getCart();
        }
      }
    );
  };

  const getDiscount = () => {
    if (cart && cart.AppliedCoupons.length > 0) {
      const isDiscount = cart.AppliedCoupons.find((item: any) => {
        return !item.coupon.get_product && !item.coupon.get_product_bundle;
      });
      if (isDiscount) {
        let total = 0;
        cart.AppliedCoupons.map((item: any) => {
          total += item.totalDiscount;
          return null;
        });
        return formatCurrency(Math.abs(total).toFixed(2));
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const DeleteCartItem = (id: number) => {
    setLoading(true);
    API.invokeApi(
      { method: 'delete', url: `${config.endpoints.getCart}/${cart.cartId}/cartitems/${id}` },
      (success: boolean, res: any) => {
        if(success){
          getCart();
        }
      }
    );
  };

  const DeleteCartItemChecker = (id: number, productId?: number) => {
    setLoading(true);
    let warrantyItems;
    if(cart && cart.cart_items.length > 0)
    {
      // To filter out the associated extend warranties of a product or product bundle
      warrantyItems = cart.cart_items.filter(item => (item.extendContract && (item.extendContract.associatedProductId === `${productId}` || item.extendContract.associatedBundleId === `${productId}`)))
    }
    if(warrantyItems.length>0)
    {
      warrantyItems.forEach(item => {
        DeleteCartItem(item.id);
      });
    }
    DeleteCartItem(id);
  };

  const getPrice = (item: any, type: number) => {
    if (item.product.ProductVariant.length > 0) {
      const Variants = item.product.ProductVariant.find((v: any) => v.label === item.variantLabel);
      const Variant = Variants.Variants.find((v: any) => v.value === item.selectedOption);
      const price = Variant.newPrice !== null ? Variant.newPrice : Variant.price;
      if (type === 1) {
        return price === null ? item.product.price : price;
      }
      if (type === 2) {
        return price === null ? item.product_bundle.price : price;
      }
    }
  };

  const VariantImage = (item: any) => {
    if (item.variantLabel) {
      if (item.product.ProductVariant.length > 0) {
        let img: any;
        item.product.ProductVariant.map((it: any) => {
          if (it.Variants.length > 0) {
            img = it.Variants.find((i: any) => {
              return i.value === item.selectedOption;
            });
          }
          return null;
        });
        return img.images[0] && img.images.length > 0
          ? config.baseUrl + img.images[0].url
          : item.product.image
            ? config.baseUrl + item.product.image.url
            : '';
      }
    } 
    else {
      if(item.product.cartImage){
        return config.baseUrl + item.product.cartImage.url
      }
      if(item.product.image){
        return item.product.image ? config.baseUrl + item.product.image.url : '';
      }
    }
  };

  const getQuantity = (id: number) => {
    if (cart && cart.cart_items.length) {
      const ids = cart.cart_items.find((item: any) => {
        if (item.product && item.product.id === id) {
          return item;
        } else if (item.product_bundle && item.product_bundle.id === id) {
          return item;
        } else {
          return null;
        }
      });
      return ids ? ids.quantity : 0;
    }
  };

  const priceFormating = (item: any) => {
    if (item.product.newPrice !== null) {
      return formatCurrency(Math.abs(item.product.newPrice).toFixed(2));
    } else {
      if (!item.product.price && item.product.price !== 0) {
        return formatCurrency(Math.abs(getPrice(item, 1)).toFixed(2));
      } else {
        return formatCurrency(Math.abs(item.product.price).toFixed(2));
      }
    }
  };

  const priceFormatingBundle = (item: any) => {
    if (item.product_bundle.newPrice !== null) {
      return formatCurrency(Math.abs(item.product_bundle.newPrice).toFixed(2));
    } else {
      if (!item.product_bundle.price) {
        return formatCurrency(Math.abs(getPrice(item, 1)).toFixed(2));
      } else {
        return formatCurrency(Math.abs(item.product_bundle.price).toFixed(2));
      }
    }
  };

  const googleAnalyticsQuantityChange = (item: any, quantity: number) => {
    let price = 0;
    let title = '';
    let items = {};
    let id;
    let discount = 0;
    let category = '';
    if (item.selectedOption) {
      items = { ...items, variant: item.selectedOption };
    }
    if (item.product) {
      title = item.product.displayTitle ? item.product.displayTitle : item.product.title;
      id = item.product.id;
      if (item.selectedOption && item.product.ProductVariant && item.product.ProductVariant.length > 0) {
        const ProductVariant = item.product.ProductVariant.find((element: any) => item.variantLabel === element.label);
        if (ProductVariant) {
          const variant = ProductVariant.Variants.find((ele: any) => ele.value === item.selectedOption);
          if (variant && (variant.newPrice || variant.price)) {
            price = variant.newPrice !== null ? variant.newPrice : variant.price;
            discount = variant.newPrice !== null ? variant.price - variant.newPrice : 0;
          } else {
            price = item.product.newPrice !== null ? item.product.newPrice : item.product.price;
            discount = item.product.newPrice !== null ? item.product.price - item.product.newPrice : 0;
          }
        } else {
          price = item.product.newPrice !== null ? item.product.newPrice : item.product.price;
          discount = item.product.newPrice !== null ? item.product.price - item.product.newPrice : 0;
        }
      } else {
        price = item.product.newPrice !== null ? item.product.newPrice : item.product.price;
        discount = item.product.newPrice !== null ? item.product.price - item.product.newPrice : 0;
      }
      if (item.product.product_categories && item.product.product_categories.length > 0) {
        item.product.product_categories.forEach((element: any, i: number) => {
          if (element.name) {
            category += element.name;
            if (i < item.product_categories.length) {
              category += '/';
            }
          }
        });
        items = { ...items, category: category };
      }
    }
    if (item.product_bundle) {
      title = item.product_bundle.displayTitle ? item.product_bundle.displayTitle : item.product_bundle.title;
      id = item.product_bundle.id;
      if (item.selectedOption && item.product_bundle.ProductVariant && item.product_bundle.ProductVariant.length > 0) {
        const ProductVariant = item.product_bundle.ProductVariant.find(
          (element: any) => item.variantLabel === element.label
        );
        if (ProductVariant) {
          const variant = ProductVariant.Variants.find((ele: any) => ele.value === item.selectedOption);
          if (variant && (variant.newPrice || variant.price)) {
            price = variant.newPrice !== null ? variant.newPrice : variant.price;
            discount = variant.newPrice !== null ? variant.newPrice - variant.price : 0;
          } else {
            price = item.product_bundle.newPrice !== null ? item.product_bundle.newPrice : item.product_bundle.price;
            discount =
              item.product_bundle.newPrice !== null ? item.product_bundle.price - item.product_bundle.newPrice : 0;
          }
        } else {
          price = item.product_bundle.newPrice !== null ? item.product_bundle.newPrice : item.product_bundle.price;
          discount =
            item.product_bundle.newPrice !== null ? item.product_bundle.price - item.product_bundle.newPrice : 0;
        }
      } else {
        price = item.product_bundle.newPrice !== null ? item.product_bundle.newPrice : item.product_bundle.price;
        discount = item.product_bundle.newPrice !== null ? item.product_bundle.price - item.product_bundle.newPrice : 0;
      }
      if (item.product_bundle.product_categories && item.product_bundle.product_categories.length > 0) {
        item.product_bundle.product_categories.forEach((element: any, i: number) => {
          if (element.name) {
            category += element.name;
            if (i < item.product_categories.length) {
              category += '/';
            }
          }
        });
        items = { ...items, category: category };
      }
    }
    items = {
      ...items,
      id: id,
      name: title,
      discount: discount !== null ? +Math.abs(discount).toFixed(2) : 0,
      affiliation: 'Nexersys store',
      brand: 'Nexersys',
      price: price !== null ? price : 0,
      currency: 'USD',
      quantity: quantity
    };
    const data = {
      currency: 'USD',
      items: [items],
      value: quantity * price
    };
    eventQuantityChange(data);
  };

  const handleOpenAssembly = () => {
    setShowAssembly(true)
  }

  const handleHideAssembly = () => {
    setShowAssembly(false)
  }

  // This function adds the extend warranty price to the sub total of the product
  const getSubTotalAfterExtendWarranty = (productId: number, productPrice: number) => {
    let warrantyItems, extendWarrantyCost=0;
    if(cart && cart.cart_items.length > 0)
    {
      // To filter out the associated extend warranties of a product or product bundle
      warrantyItems = cart.cart_items.filter(item => (item.extendContract && (item.extendContract.associatedProductId === `${productId}` || item.extendContract.associatedBundleId === `${productId}`)))
    }
    if(warrantyItems.length>0)
    {
      // Here we will calculate the total cost of the extend warranty associated with the product
      warrantyItems.forEach(item => {
        extendWarrantyCost+=item.extendContract.planPrice;
      });
    }
    return formatCurrency(Math.abs(productPrice + (extendWarrantyCost)).toFixed(2))
  };

  return (
    <>
    {showAssembly && 
      <WhiteGloveAssembly 
        handleHideAssembly={handleHideAssembly} 
        setCartItems={setCartItems}
        showLoader={showLoader} 
        hideLoader={hideLoader} 
        data={assemblyData}
      />
    }
    <div className="app-cart-slider-container">
      <MyToast message={toast.message} visible={toast.visible} type={toast.type} timeOut={toast.timeOut} />
      {status && <div className="app-overlay" onClick={onToggle} />}
      <div className={'app-cart-slider ' + (status ? ' ' : 'app-cart-slider-hidden')}>
        <div className="app-cart-slider-header">
          <span>Your Cart</span>
          <span
            onClick={() => {
              onToggle();
              setIsCouponHidden(false);
            }}
          >
            {icon.close(color.$black, '')}
          </span>
        </div>
        {Loading && <div className="app-cart-body-overlay">{icon.loading('#000', 'fa-2x')}</div>}
        <div
          className="app-cart-slider-body"
          style={{ height: `calc(100% - ${getDiscount() === 0 ? '307px' : '329px'})` }}
        >
          {cart &&
            cart.cart_items.length > 0 &&
            cart.cart_items.map((item: any, i: number) => {
              if (item.product && !item.extendContract) {
                return (
                  <div key={i} className="app-single-item">
                    <div className="app-cart-item-i-t">
                      <Link href={`/product/${item.product.slug}`} aria-label={item.product.slug}>
                        <a><div
                          className="item-image"
                          style={{
                            backgroundImage: `url(${VariantImage(item)})`
                          }}
                        /></a>
                      </Link>
                      <div className="item-title">
                        <span className="app-item-data">
                          <Link href={`/product/${item.product.slug}`}>
                            <a>{item.product.title ? item.product.title : item.product.displayTitle}</a>
                          </Link>
                        </span>
                        {item.variantLabel && (
                          <span className="app-item-data">
                            <strong>{item.variantLabel}</strong>: {item.selectedOption}
                          </span>
                        )}
                        <span className="app-item-data">Price: ${priceFormating(item)}</span>
                      </div>
                      <span
                        className="item-delete"
                        onClick={() => {
                          DeleteCartItemChecker(item.id,item.product.id);
                          googleAnalyticsRemoveFromCart(item);
                        }}
                      >
                        {icons.remove(color.$black, 'fa-sm')}
                      </span>
                    </div>
                    {item.product.tangible && (
                      <QuantityComponent
                        quantity={item.quantity}
                        onChange={(quantity: number, id: number) => {
                          UpdateCartItem(quantity, id, item.product.id);
                          googleAnalyticsQuantityChange(item, quantity);
                        }}
                        id={item.id}
                        enableZero={true}
                      />
                    )}
                    <ExtendWarrantyCart 
                      productId={item.product.id} 
                      productQuantity={item.quantity}
                      setLoading={(e)=>{setLoading(e)}}
                      setCartItems={setCartItems}
                      cart={cart}
                      isBundle={false}
                    />
                    <div className="app-cart-item-price">${getSubTotalAfterExtendWarranty(item.product.id,item.subTotal)}</div>
                  </div>
                );
              }
              if (item.product_bundle) {
                return (
                  <div key={i} className="app-single-item">
                    <div className="app-cart-item-i-t">
                      <Link href={`/product/${item.product_bundle.product.slug}`} aria-label="productlink">
                        <a><div
                          className="item-image"
                          style={{
                            backgroundImage: `url(${item.product_bundle?.image ? config.baseUrl + item.product_bundle.image[0]?.url : ''
                              })`
                          }}
                        /></a>
                      </Link>
                      <div className="item-title">
                        <span className="app-item-data">
                          <Link href={`/product/${item.product_bundle.slug}`}>
                            <a> {item.product_bundle.displayTitle} {item.product_bundle.title}</a>
                          </Link>
                        </span>
                        <span className="app-item-data">Price: ${priceFormatingBundle(item)}</span>
                      </div>
                      <span
                        className="item-delete"
                        onClick={() => {
                          DeleteCartItemChecker(item.id,item.product_bundle.id);
                          googleAnalyticsRemoveFromCart(item);
                        }}
                      >
                        {icons.remove(color.$black, 'fa-sm')}
                      </span>
                    </div>
                    <QuantityComponent
                      quantity={item.quantity}
                      onChange={(quantity: number, id: number) => {
                        UpdateCartItem(quantity, id, item.product_bundle.id);
                        googleAnalyticsQuantityChange(item, quantity);
                      }}
                      id={item.id}
                      enableZero={true}
                    />
                    <ExtendWarrantyCart 
                      productId={item.product_bundle.id} 
                      productQuantity={item.quantity}
                      setLoading={(e)=>{setLoading(e)}}
                      setCartItems={setCartItems}
                      cart={cart}
                      isBundle={true}
                    />
                    <div className="app-cart-item-price">${getSubTotalAfterExtendWarranty(item.product_bundle.id,item.subTotal)}</div>

                    {item.VariantOptions.map((v: any, i: number) => {
                      return (
                        <div key={i} className="app-bundle-selected">
                          <Link href={`/product/${v.product.slug}`}>
                            <a> <span>{v.product.displayTitle}</span></a>
                          </Link>
                          {v.variantLabel && v.selectedOption && (
                            <span>
                              <strong>{v.variantLabel}</strong>:{v.selectedOption}
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              }
              return null;
            })}
          {cart &&
            cart.cart_items &&
            cart.cart_items.length > 0 &&
            cart.AppliedCoupons &&
            cart.AppliedCoupons.length > 0 &&
            cart.AppliedCoupons.map((item: any, i: number) => {
              return (
                (item.coupon.get_product || item.coupon.get_product_bundle) && (
                  <div key={i} className="app-single-item">
                    <div className="app-cart-item-i-t">
                      <div
                        className="item-image"
                        style={{
                          backgroundImage: `url(${config.baseUrl +
                            (item.coupon.get_product && item.coupon.get_product.image
                              ? item.coupon.get_product.image.url
                              : item.coupon.get_product_bundle && item.coupon.get_product_bundle.image
                                ? item.coupon.get_product_bundle.image.url
                                : '')})`
                        }}
                      />

                      <div className="item-title">
                        <span className="app-item-data">
                          <strong>FREE </strong>
                          {item.coupon.get_product
                            ? item.coupon.get_product.title
                            : item.coupon.get_product_bundle
                              ? item.coupon.get_product_bundle.title
                              : ''}
                        </span>

                        <span className="app-item-data">
                          Quantity:
                          {getQuantity(
                            item.coupon.buy_product ? item.coupon.buy_product.id : item.coupon.buy_product_bundle.id
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          {cart && cart.cart_items.length <= 0 && <div className="app-cart-no-items"> Your Cart is Empty </div>}
        </div>

        {cart && cart.cart_items.length > 0 && (
          <div className="app-cart-slider-footer">
            {
             pathValues[asPath?.split("/")[1]] || pathValues[asPath?.split("/")[2]] ? 
              <div className="white-glove-wrapper">
              <h6>Need help with assembly?</h6>
              <h6 className="assembly-info" onClick={handleOpenAssembly}><a>White Glove Assembly Info</a></h6>
            </div> : null
            }
            {priceSections.map((item: any, i: number) => {
              const discount = getDiscount();
              if (item === 'discount') {
                return (
                  discount !== 0 && (
                    <div key={i} className="app-footer-price-section" style={{ color: 'red' }}>
                      <span>{item}</span>
                      <span>${discount}</span>
                    </div>
                  )
                );
              } else if (item === 'tax') {
                let cartTax;
                if (cart.tax == 0) {
                  cartTax = 'TBD'
                } else {
                  cartTax = `$${formatCurrency(Math.abs(cart[`${item}`]).toFixed(2))}`
                }
                return (
                  <div
                    key={i}
                    className="app-footer-price-section"
                    style={{ color: item === 'discount' ? 'red' : 'black' }}
                  >
                    <span>{item}</span>
                    <span>{cartTax}</span>
                  </div>
                );
              }else {
                return (
                  <div
                    key={i}
                    className="app-footer-price-section"
                    style={{ color: item === 'discount' ? 'red' : 'black' }}
                  >
                    <span>{item}</span>
                    <span>${formatCurrency(Math.abs(cart[`${item}`]).toFixed(2))}</span>
                  </div>
                );
              }
            })}
            <Link href={ROUTE.checkout} >
              <a><div className="app-footer-btn" onClick={onToggle}  >Checkout</div></a>
            </Link>
            <div className="app-footer-coupon-section">
              {isCouponHidden ? (
                <React.Fragment>
                  <input
                    className="footer-text-box"
                    type="text"
                    placeholder="Enter Promo Code"
                    value={coupon}
                    onChange={(event: any) => {
                      setCoupon(event.target.value);
                    }}
                    onKeyPress={keyPress}
                  />
                  <button className="footer-button" onClick={applyCoupon}>
                    Apply
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {
                    <p
                      onClick={() => {
                        setIsCouponHidden(true);
                      }}
                      className="footer-coupon-disable"
                    >
                      Have a Coupon?
                    </p>
                  }
                </React.Fragment>
              )}
            </div>
            <div className="app-footer-note">*Only one coupon can be applied per purchase</div>
          </div>
        )}
      </div>
    </div>
    </>
  );
};

const mapState = (state: any) => {
  return {
    status: state.cartStatus,
    cart: state.cart
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    onToggle: () => dispatch({ type: 'TOGGLE_CART' }),
    setCartItems: (cartItems: any) => {
      dispatch({ type: 'ON_CART_LOAD', payload: cartItems });
    },
    showLoader: () => dispatch({ type: 'LOADER_SHOW' }),
    hideLoader: () => dispatch({ type: 'LOADER_HIDE' })
  };
};

export default connect(
  mapState,
  mapDispatch
)(CartSlider);
