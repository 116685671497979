import React, { useEffect, useState } from "react";
import { createStoreHook } from "react-redux";
import color from "../../assets/colors";
import icons from "../../assets/icons/icons";
import config from "../../config/config";
import appService from "../../services/appService";
import httpService from "../../services/httpService";
import API from '../../services/httpService';

export default function ExtendWarrantyCart({productId,productQuantity,setLoading,setCartItems,cart, isBundle}) {
    const [extendWarrantyId,setExtendWarrantyId] = useState(undefined);
    const [count, setCount] = useState(0);
    const cartId = appService.getKey('cartid');

    // In the below useEffect, we have requested product ID of Extend Warranty
    useEffect(()=>{
        let isActive = true;
        setLoading(true);
        httpService.invokeApi(
          {
            url: `${config.endpoints.getExtendWarrantyType}?productType=Warranty&title=Extended Warranty`,
            method: 'get',
          }
          ,
          (success: boolean, res: any) => {
            if (isActive) {
                    if(success) {
                        setExtendWarrantyId(res[0].id);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                }
            }
        ); 
        return () => {
            isActive = false;
        }
    },[]);

    // In the below Hook, total extend warranties and product quantities are kept measure
    useEffect(()=>{
        let warrantyItems;
        if(cart && cart.cart_items.length > 0)
        {
            if(isBundle)
            {
                warrantyItems = cart.cart_items.filter(item => (item.extendContract && item.extendContract.associatedBundleId === `${productId}`))
            } else {
                warrantyItems = cart.cart_items.filter(item => (item.extendContract && item.extendContract.associatedProductId === `${productId}`))
            }
        }
        const countWarrantyItems = warrantyItems.length;
        setCount(countWarrantyItems);
        // If there are additional warrantyItems, then they are removed
        if(countWarrantyItems > productQuantity) {
            for(let i=productQuantity;i<countWarrantyItems;i++)
            DeleteCartItem(warrantyItems[i].id);
        }
        if(extendWarrantyId!==undefined) {
            renderProductWarrantyTab();
        }
    });

    // Extend Buttons for cart is rendered in below function
    const renderProductWarrantyTab = () => {
        //@ts-ignore
        if(document.getElementById(`extend-cart-product-${productId}`) !== null && window.Extend.buttons.instance(`#extend-cart-product-${productId}`) === null && count<productQuantity)
        {
            //@ts-ignore
            if(window.Extend)
            {
            //@ts-ignore
            window.Extend.buttons.renderSimpleOffer(`#extend-cart-product-${productId}`, {
                referenceId: `${productId}`,
                onAddToCart:
                function({ plan, product }) {
                    if (plan && product && extendWarrantyId !== undefined) {
                    const cartid = appService.getKey('cartid');
                    let data; 
                    if(isBundle)
                    {
                        data = {
                            productId: extendWarrantyId,
                            associatedBundleId: productId,
                            quantity: 1,
                            planId: plan.planId,
                            price: plan.price/100,
                            duration: plan.term
                        }
                    } else {
                        data = {
                            productId: extendWarrantyId,
                            associatedProductId: productId,
                            quantity: 1,
                            planId: plan.planId,
                            price: plan.price/100,
                            duration: plan.term
                        }
                    }
                    setLoading(true);
                    httpService.invokeApi(
                        {
                            url: `${config.endpoints.getCart}/${cartid}/cartitems`,
                            method: 'put',
                            data
                        },
                        (success: boolean, res: any) => {
                        if (success && res.id) {
                            const request: any = { method: 'get', url: config.endpoints.getCart };
                            if (cartid) {
                            request.headers = { cartid: cartid };
                            }
                            httpService.invokeApi(request, (success: boolean, resp: any) => {
                            if (success && resp.cartId) {
                                appService.setKey('cartid', resp.cartId);
                                setCartItems(resp);
                                setLoading(false);
                            } else {
                                setLoading(false);
                            }
                            });
                        } else {
                            setLoading(false);
                        }
                        }
                    );
                    }
                },
            })
            }
        }
    }

    const DeleteCartItem = (id: number) => {
        setLoading(true);
        API.invokeApi(
          { method: 'delete', url: `${config.endpoints.getCart}/${cart.cartId}/cartitems/${id}` },
          (success: boolean, res: any) => {
            if (success) {
              getCart();
            }
          }
        );
    };

    const getCart = () => {
        const request: any = { method: 'get', url: config.endpoints.getCart };
        if (cartId) {
            request.headers = { cartid: cartId };
        }
        API.invokeApi(request, (success: boolean, res: any) => {
            if (success) {
            appService.setKey('cartid', res.cartId);
            setCartItems(res);
            }
            setLoading(false);
        });
    };

    // In the below function, the extend warranty duration statement is returned
    const getYear = (duration: number) => {
        const time = `${duration/12}`;
        const text =  (time==='1'?" year":" years") + " Extend Protection Plan added";
        return time + text;
    }

    return (
        <>
            <div className="extend-warranty-cart-message-set">
                {cart &&
                cart.cart_items.length > 0 &&
                cart.cart_items.map((item: any, i: number) => {
                if (item.extendContract && (item.extendContract.associatedProductId === `${productId}` || item.extendContract.associatedBundleId === `${productId}`)) {
                    return (
                        <div className="extend-warranty-cart-container" key={item.id}>
                            <div className="extend-warranty-cart-text">
                                <div className="extend-warranty-cart-text-description">{getYear(item.extendContract.duration)}</div>
                                <div><b>Price:</b> ${item.extendContract.planPrice}.00</div>
                            </div>
                            <div
                                className="item-delete"
                                onClick={() => {
                                    DeleteCartItem(item.id);
                                }}
                            >
                                {icons.remove(color.$black, 'fa-sm')}
                            </div>
                        </div>
                    );
                }
                return null;
                })}
            </div>
            {count<productQuantity && (
                <div 
                    className="extend-warranty-cart-button" 
                    id={`extend-cart-product-${productId}`}
                ></div>  
            )}
        </>
    )
}